import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../../layouts/layout'
import Seo from '../../components/seo'
import WeAreSocietyOne from '../../components/UI/HeaderAndImageOnBottom'
import LeadingPlatform from '../../components/ForAboutUs/LeadingPlatform'
import AwardWinningPLs from '../../components/UI/AwardWinningPLs'
import BorrowersAndInvestors from '../../components/ForAboutUs/BorrowersAndInvestors'
import Goals from '../../components/ForAboutUs/WeAreKickingGoals'
import OtherLinks from '../../components/ForAboutUs/OtherLinks'
import MoneyMeGroup from '../../components/ForAboutUs/MoneyMeGroup'
import { parseJson } from '../../support'

const AboutUs = ({ path }) => {
  const queriedStructuredDataConfigForAboutUs = useStaticQuery(graphql`
    query structuredDataConfigForAboutUsQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "about-us" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForAboutUs.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  return (
    <Layout>
      <Seo 
        title="Hi, we are SocietyOne"
        description="SocietyOne's mission is to provide a better deal for borrowers and investors"
        path={path}
        structuredData={structuredData} />
      <WeAreSocietyOne slugQuery="/about-us" />
      <LeadingPlatform />
      <MoneyMeGroup />
      <hr className="solid" />
      <AwardWinningPLs />
      <BorrowersAndInvestors />
      <Goals />
      <hr className="solid" />
      <OtherLinks />
    </Layout>
  )
}

export default AboutUs