import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import CMSImage from '../CMSImage'

const ImageCard = (props) => {
  const {
    to,
    titleHtml,
    children,
    imgAlt,

    // for gatsby image
    imgPath,

    // for svg files
    internalSvgType,
    svgContent,
    svgFileTypeAndURL,

    // styling
    width,
    padding,
    background,
    border,
    textColor,
    cardStyleOnHover,
    imgHeight,
    imgStyleOnHover,
  } = props

  return (
    <CardWrapper
      width={width}
      padding={padding}
      background={background}
      border={border}
      textColor={textColor}
      cardStyleOnHover={cardStyleOnHover}
      imgHeight={imgHeight}
      imgStyleOnHover={imgStyleOnHover}
    >
      {to ? (
        <Link to={to}>
          {imgAlt && (
            <div className="imgWrapper">
              <CMSImage
                alt={imgAlt}
                gatsbyImageData={imgPath}
                internal={internalSvgType}
                file={svgFileTypeAndURL}
                svg={svgContent}
              />
            </div>
          )}
          <div className="desc">
            <div
              dangerouslySetInnerHTML={{
                __html: titleHtml,
              }}
            />
            {children}
          </div>
        </Link>
      ) : (
        <>
          {imgPath && (
            <div className="imgWrapper">
              <CMSImage
                alt={imgAlt}
                gatsbyImageData={imgPath}
                internal={internalSvgType}
                file={svgFileTypeAndURL}
                svg={svgContent}
              />
            </div>
          )}
          <div className="desc">
            <div
              dangerouslySetInnerHTML={{
                __html: titleHtml,
              }}
            />
            {children}
          </div>
        </>
      )}
    </CardWrapper>
  )
}

const CardWrapper = styled.div`
  background: ${(props) => props.background || 'var(--white)'};
  color: ${(props) => props.textColor || 'var(--base-navy)'};
  border: ${(props) => props.border || 'none'};
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
  border-radius: 10px;
  width: ${(props) => props.width || '300px'};
  margin: 12px 0px;
  padding: ${(props) => props.padding || 0};
  &:hover {
    ${(props) => props.cardStyleOnHover || ''}
    img {
      ${(props) => props.imgStyleOnHover || ''}
    }
  }
  h2 {
    margin: 20px auto;
  }
  overflow: hidden;
  .imgWrapper {
    height: ${(props) => props.imgHeight};
    width: auto;
    overflow: hidden;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg {
    object-fit: cover;
    width: 100%
    height: 100%;
  }
  .desc {
    h2 {
      font-size: 28px;
    }
    @media screen and (max-width: 768px) {
      h2 {
        font-size: 22px;
      }
    }
    padding: 24px;
    button {
      margin-top: 20px;
    }
  }
  a {
    color: ${(props) => props.textColor || 'var(--base-navy)'} !important;
  }
`

export default ImageCard
