import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import ContentContainer from '../UI/ContentContainer'

const WeAreKickingGoals = () => {
  const data = useStaticQuery(
    graphql`
      query getAllYearlyTimeline {
        allContentfulComponentYearlyTimeline(sort: {fields: year, order: ASC}) {
          nodes {
            milestones {
              childMarkdownRemark {
                html
              }
            }
            year
          }
        }
      }
    `
  )

  const allGoals = () => {
    const items = []
    data.allContentfulComponentYearlyTimeline.nodes.forEach((g, i) => {
      items.push(
        <div className="content" key={i}>
          <h3 className="year">{ g.year }</h3>
          <div className="body" dangerouslySetInnerHTML={{__html: g.milestones.childMarkdownRemark.html}} />
        </div>
      )
    })
    return items
  }

  return (
    <ContentContainer>
      <h2 className="text-center">We're Kicking Goals</h2>
      <Timeline>
        {allGoals()}
      </Timeline>
    </ContentContainer>
  )
}

const Timeline = styled.div`
  margin: 100px auto 0;
  max-width:960px;
  width:100%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left:calc(33% + -1px);
    bottom: 0px;
    border-left: 1px dashed #e2e2e2
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .content{
    clear: both;
    text-align: left;
    position: relative;
    .year {
      font-size: 50px;
      margin-bottom: .5em;
      float: left;
      width: 33%;
      padding-right: 60px;
      text-align: right;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border: 4px solid var(--base-green);
        background-color: var(--base-green);
        border-radius:100%;
        top: 30%;
        right: -8px;
        z-index: 99;
      }
    }
  }
  .body {
    white-space: pre-wrap;
    margin: 0 0 3em;
    float: right;
    width: 66%;
    padding-left: 50px;
  }
  @media screen and (max-width: 768px) {
    &:before {
      left: calc(10% + 2px);
    }
    .year {
      font-size: 30px;
      width: 100% !important;
      text-align: left !important;
      padding-left: 15% !important;
      &:before {
        left: 9%;
      }
    }
    .body {
      padding-left: 15% !important;
      width: 100%;
      padding-left: 0;
    }
  }
`

export default WeAreKickingGoals