import React from 'react'

import TopicDescAndImg from '../UI/TopicDescAndImg'
import ContentContainer from '../UI/ContentContainer'

const BorrowersAndInvestors = () => {

  return (
    <ContentContainer background="var(--base-gray)">
      <TopicDescAndImg categoryQuery="For Borrowers and Investors" />
    </ContentContainer>
  )
}

export default BorrowersAndInvestors
