import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import ImageCard from '../UI/Card/ImageCard'
import ContentContainer from '../UI/ContentContainer'
import HomeButton from '../UI/Buttons/HomeButton'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'

const OtherLinks = () => {
  const iconsQuery = useStaticQuery(graphql`
    query {
      allAboutPageLinkImagesJson {
        nodes {
          title
          link
          alt
          img {
            internal {
              mediaType
            }
            svg {
              content
            }
          }
        }
      }
    }
  `)

  const links = (data) => {
    const itemsArray = []
    data.allAboutPageLinkImagesJson.nodes.forEach((item, i) => {
      itemsArray.push(
        <span key={i}>
          <ImageCard
            to={item.link}
            titleHtml={item.title}
            imgAlt={item.alt}
            internalSvgType={item.img.internal}
            svgContent={item.img.svg}
            padding="24px 0"
            border="1px solid #d4dbe0"
            cardStyleOnHover="transform: translate(0, -20px);
            transition: 0.5s; 
            .imgWrapper{background-color: #d2f2e2;} 
            button{background-color: #1dbc6e;} 
            svg{g {fill: var(--base-green);}}"
          >
            <HomeButton
              height={BUTTON_SIZE.HEIGHT_S}
              padding={BUTTON_SIZE.PADDING_S}
              fontSize={BUTTON_SIZE.FONT_SIZE_S}
              background={BUTTON_COLOR.TEXT_COLOR_BLUE}
              backgroundHover={BUTTON_COLOR.GREEN}
              color={BUTTON_COLOR.TEXT_COLOR_WHITE}
              text="View More"
            />
          </ImageCard>
        </span>
      )
    })
    return itemsArray
  }

  return (
    <ContentContainer>
      <LinksWrapper>{links(iconsQuery)}</LinksWrapper>
    </ContentContainer>
  )
}

const LinksWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  .imgWrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #d4dbe0;
    svg {
      width: 80px;
    }
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`

export default OtherLinks
