import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton';

const MoneyMeGroup = () => {
  return (
    <ContentContainer>
      <MoneyMeGroupWrapper>
        <h2 className="text-center">Part of the MoneyMe Group</h2>
        <p className="text-center" style={{maxWidth: 800, margin: "24px auto 40px"}}>
          SocietyOne is a wholly owned subsidiary of ASX-listed MoneyMe Ltd.
          Learn more about MoneyMe and the leadership team at MoneyMe’s website.{' '}
        </p>
        <HomeButton
						height={BUTTON_SIZE.HEIGHT_L}
						padding={BUTTON_SIZE.PADDING_L}
						fontSize={BUTTON_SIZE.FONT_SIZE_L}
						background={BUTTON_COLOR.GREEN}
						backgroundHover={BUTTON_COLOR.GREEN_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_WHITE}
						text='Learn More'
						handleClick={() => {navigate('https://investors.moneyme.com.au/')}}
					/>
      </MoneyMeGroupWrapper>
    </ContentContainer>
  )
}

const MoneyMeGroupWrapper = styled.div``

export default MoneyMeGroup
