import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import ContentContainer from './ContentContainer'

const AwardWinningPLs = () => {

  return (
    <ContentContainer>
      <Header>Award winning personal loans</Header>
      <AwardList>
        <ForDesktop>
          <StaticImage src='../../assets/images/PLAwardListForDesktop.png' alt="PLAwardList" placeholder='none' loading='eager' />
        </ForDesktop>
        <ForMobile>
          <StaticImage src='../../assets/images/PLAwardList.png' alt="PLAwardList" placeholder='none' loading='eager' />
        </ForMobile>
      </AwardList>
    </ContentContainer>
  )
}
const AwardList = styled.div`
  margin: 0 auto;
  width: 960px;
  @media screen and (max-width: 991px) {
    width: 100%;
  } ;
`

const Header = styled.h2`
  text-align: center;
  margin: 0 0 40px 0;
  @media screen and (max-width: 991px) {
    font-size: 32px;
  } ;
`
const ForDesktop = styled.div`
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const ForMobile = styled.div`
  display: none;
@media screen and (max-width: 768px) {
  display: block;
  margin: 0 auto;
  width: 100%;
}
`

export default AwardWinningPLs
