import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'
import TopicDescAndImg from '../UI/TopicDescAndImg'

const LeadingPlatform = () => {
  return (
    <ContentContainer>
      <LeadingPlatformWrapper id="leading-platform">
        <h2 className="text-center">
          SocietyOne is Australia's leading
          <br />
          <span className="greenHeader">digital finance platform</span>
        </h2>
        <TopicDescAndImg categoryQuery="Leading Platform" />
      </LeadingPlatformWrapper>
    </ContentContainer>
  )
}

const LeadingPlatformWrapper = styled.div`
  margin-top: -120px;
  padding-top: 120px;
  h2 {
    margin-bottom: 80px;
  }
`

export default LeadingPlatform
